<template>
	<div class="container">
		<el-breadcrumb separator="/">
			<el-breadcrumb-item>管理后台</el-breadcrumb-item>
			<el-breadcrumb-item>组织架构</el-breadcrumb-item>
			<el-breadcrumb-item>人员管理</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-row>
				<el-col :span="24">
					<el-form :inline="true">
						<el-form-item>
							<el-input size="small" placeholder="姓名" v-model="searchName"></el-input>
						</el-form-item>
						<el-form-item>
							<el-input size="small" placeholder="职务" v-model="searchJob"></el-input>
						</el-form-item>
						<el-form-item>
							<el-select v-model="searchDepartment1" size="small" placeholder="一级部门"
								:disabled="branchArr[0] ? true:false" @change="choiceA">
								<el-option v-for="item in department1List" :key="item.branch" :label="item.branch"
									:value="item.branch">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-select v-model="searchDepartment2" v-if="department2List.length>0" size="small"
								placeholder="二级部门" :disabled="branchArr[1] ? true:false" @change="choiceB">
								<el-option v-for="item in department2List" :key="item.branch" :label="item.branch"
									:value="item.branch">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-select v-model="searchDepartment3" v-if="department3List.length>0" size="small"
								placeholder="三级部门" :disabled="branchArr[2]  ? true:false" @change="choiceC">
								<el-option v-for="item in department3List" :key="item.branch" :label="item.branch"
									:value="item.branch">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-select v-model="searchDepartment4" v-if="department4List.length>0"
								:disabled="branchArr[3] ? true:false" size="small" placeholder="四级部门">
								<el-option v-for="item in department4List" :key="item.branch" :label="item.branch"
									:value="item.branch">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" size="small" @click="search">查询</el-button>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" size="small" @click="reset">重置</el-button>
						</el-form-item>
					</el-form>
				</el-col>
				<el-col :span="24">
					<el-form :inline="true">
						<el-form-item>
							<el-button type="primary" size="small" @click="add">添加人员</el-button>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" size="small" @click="batch">批量导入</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<el-table :data="personnelData" border stripe style="width: 100%">
				<el-table-column prop="nickname" label="姓名">
				</el-table-column>
				<el-table-column prop="job" label="职务">
				</el-table-column>
				<el-table-column prop="phone" label="电话">
				</el-table-column>
				<el-table-column prop="gender" label="性别">
				</el-table-column>
				<el-table-column prop="birthday" label="生日">
				</el-table-column>
				<el-table-column prop="branch_1" label="一级部门">
				</el-table-column>
				<el-table-column prop="branch_2" label="二级部门">
				</el-table-column>
				<el-table-column prop="branch_3" label="三级部门">
				</el-table-column>
				<el-table-column prop="branch_4" label="四级部门">
				</el-table-column>
				<el-table-column label="添加日期">
					<template slot-scope="scope">
						{{createTime(scope.row.createTime)}}
					</template>
				</el-table-column>
				<el-table-column label="操作"  width="145">
					<template slot-scope="scope">
						<el-button type="success" size="mini" @click="edit(scope.row)">编辑</el-button>
						<el-button type="danger" size="mini" @click="remove(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagenum"
				:page-sizes="[5,10,15,20]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
			<el-dialog title="添加人员" :visible.sync="addVisible" width="580px">
				<div style="width: 480px;">
					<el-form ref="form" label-width="120px">
						<el-form-item label="姓名">
							<el-input v-model="addName"></el-input>
						</el-form-item>
						<el-form-item label="职务">
							<el-input v-model="addJob"></el-input>
						</el-form-item>
						<el-form-item label="电话号码">
							<el-input v-model="addPhone"></el-input>
						</el-form-item>
						<el-form-item label="性别">
							<el-select size="small" v-model="userGender" placeholder="性别">
								<el-option label="男" value="男"></el-option>
								<el-option label="女" value="女"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="出生年月">
							<el-date-picker v-model="userBirthday" type="date" placeholder="选择日期"
								format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
							</el-date-picker>
							非必填
						</el-form-item>
						<el-form-item label="所属部门">
							<el-cascader v-model="selectedDepartment" :options="p_data" :props="cascaderProps"
								@change="parentDepartmentChanged" :clearable="true" :change-on-select="true">
							</el-cascader>
						</el-form-item>
					</el-form>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button type="info" @click="closeAdd">取消</el-button>
					<el-button type="primary" @click="addPersonnel">确 定</el-button>
				</span>
			</el-dialog>
			<el-dialog title="编辑人员" :visible.sync="editVisible" width="580px">
				<div style="width: 480px;">
					<el-form ref="form" label-width="120px">
						<el-form-item label="姓名">
							<el-input v-model="editName"></el-input>
						</el-form-item>
						<el-form-item label="职务">
							<el-input v-model="editJob"></el-input>
						</el-form-item>
						<el-form-item label="电话号码">
							<el-input v-model="editPhone"></el-input>
						</el-form-item>
						<el-form-item label="性别">
							<el-select size="small" v-model="edituserGender" placeholder="性别">
								<el-option label="男" value="男"></el-option>
								<el-option label="女" value="女"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="出生年月">
							<el-date-picker v-model="edituserBirthday" type="date" placeholder="选择日期"
								format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
							</el-date-picker>
							非必填
						</el-form-item>
						<el-form-item label="所属部门">
							<el-cascader v-model="selectedDepartmentEdit" :options="p_data" :props="cascaderProps"
								@change="parentDepartmentChangedEdit" :clearable="true" :change-on-select="true">
							</el-cascader>
						</el-form-item>
					</el-form>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button type="info" @click="closeEdit">取消</el-button>
					<el-button type="primary" @click="editPersonnel">确 定</el-button>
				</span>
			</el-dialog>
			<el-dialog title="批量导入" :visible.sync="uploadVisible" width="50%">
				<div>
					<el-form ref="form" label-width="120px">
						<el-form-item label="模板文件">
							<el-button type="success" size="small">
								<el-link :href="publicUrl+'/public/excel/批量导入用户.xlsx'">下载批量导入用户模板</el-link>
							</el-button>
						</el-form-item>
						<el-form-item label="上传EXCEL">
							<el-upload class="upload-demo" action="" :http-request="excelUpload">
								<el-button size="small" type="primary">上传</el-button>
							</el-upload>
						</el-form-item>
						<el-form-item label="所属部门">
							<el-cascader v-model="selectedDepartmentUpload" :options="p_data" :clearable="true" :props="cascaderProps"
								@change="parentDepartmentChangedUpload" :change-on-select="true">
								<!-- :clearable="true" -->
								<!-- :props="cascaderProps" -->
							</el-cascader>
						</el-form-item>
					</el-form>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button type="info" @click="closeUpload">取消</el-button>
					<el-button type="primary" @click="batchUpload">确 定</el-button>
				</span>
			</el-dialog>
		</el-card>
	</div>
</template>
<script>
	import {bian_log} from '../api/log'
	import moment from "moment"
	import server_url from '../utils/baseUrl'
	import {
		department_list
	} from '../api/department'
	import {
		add_personnel,
		personnel_list,
		edit_personnel,
		remove_personnel,
		excel_upload
	} from '../api/personnel'
	export default {
		data() {
			return {
				userGender: '',
				userBirthday:'',
				edituserGender: '',
				edituserBirthday:'',
				options: [{
					value: 'zhinan',
					label: '指南',
					children: [{
						value: 'shejiyuanze',
						label: '设计原则',
						children: []
					}, {
						value: 'daohang',
						label: '导航',
						children: [{
							value: 'cexiangdaohang',
							label: '侧向导航'
						}, {
							value: 'dingbudaohang',
							label: '顶部导航'
						}]
					}]
				}],
				company: '',
				companyData: [],
				pagenum: 1,
				pagesize: 10,
				total: 0,
				searchName: '',
				searchJob: '',
				branchArr: [],
				department1List: [],
				department2List: [],
				department3List: [],
				department4List: [],
				searchDepartment1: null,
				searchDepartment2: null,
				searchDepartment3: null,
				searchDepartment4: null,
				addVisible: false,
				personnelData: [],
				addName: '',
				addPhone: '',
				addJob: '',
				p_data: [],
				cascaderProps: {
					value: 'branch',
					label: 'branch',
					children: 'children',
					// expandTrigger:'hover'
				},
				selectedDepartment: [],
				addBranch1: null,
				addBranch2: null,
				addBranch3: null,
				addBranch4: null,
				editVisible: false,
				editName: '',
				editPhone: '',
				editJob: '',
				editId: '',
				selectedDepartmentEdit: [],
				editBranch1: null,
				editBranch2: null,
				editBranch3: null,
				editBranch4: null,
				publicUrl: server_url,
				branch_content: '',
				branch_level: '',
				uploadVisible: false,
				uploadFile: '',
				selectedDepartmentUpload: [],
				uploadBranch1: null,
				uploadBranch2: null,
				uploadBranch3: null,
				uploadBranch4: null,
				removeBranch1: null,
				removeBranch2: null,
				removeBranch3: null,
				removeBranch4: null,
				oldBranch1: null,
				oldBranch2: null,
				oldBranch3: null,
				oldBranch4: null,
			};
		},
		computed: {

		},
		methods: {
			timechange() {
				this.searchDate[1] += 86399990;
			},
			createTime(t){
				if(t){
					return moment(t).format('YYYY-MM-DD HH:mm:ss')
				}
				return ''
			},
			departmentList() {
				department_list(this.company, this.searchDepartment1, this.searchDepartment2, this.searchDepartment3, this
					.searchDepartment4, ).then(res => {
					if (res.status != 200) {
						return this.$message.error('获取部门列表失败')
					}
					let branch = res.data.branch;
					branch.forEach(item => {
						item.children = JSON.parse(item.children)
					})
					this.companyData = branch;
					this.department1List = branch;
					//管理员部门查询筛选权限
					let branch_content = sessionStorage.getItem('branch-content')
					let branch_level = sessionStorage.getItem('level')
					let branchArr = [];
					if (branch_level == 1) {
						this.branchArr = []
					} else {
						branchArr = branch_content.split('|');
						this.branchArr = branchArr;
					}
					if (branchArr[0]) {
						this.searchDepartment1 = branchArr[0]
						this.department1List.forEach(item => {
							if (item.branch == this.searchDepartment1) {
								this.department2List = item.children
							}
						})
					}
					if (branchArr[1]) {
						this.searchDepartment2 = branchArr[1]
						this.department2List.forEach(item => {
							if (item.branch == this.searchDepartment2) {
								this.department3List = item.children
							}
						})
					}
					if (branchArr[2]) {
						this.searchDepartment3 = branchArr[2]
						this.department3List.forEach(item => {
							if (item.branch == this.searchDepartment3) {
								this.department4List = item.children
							}
						})
					}
					if (branchArr[3]) {
						this.searchDepartment4 = branchArr[3]
					}
					this.personnelList()
					//管理员部门添加人员权限
					this.selectedDepartment = branchArr;
					this.selectedDepartmentUpload = branchArr;
					if (this.selectedDepartment[0]) {
						this.addBranch1 = this.selectedDepartment[0]
					}
					if (this.selectedDepartment[1]) {
						this.addBranch2 = this.selectedDepartment[1]
					}
					if (this.selectedDepartment[2]) {
						this.addBranch3 = this.selectedDepartment[2]
					}
					if (this.selectedDepartment[3]) {
						this.addBranch4 = this.selectedDepartment[3]
					}
					let p_data = [];
					branch.forEach(item => {
						if (branchArr[0]) {
							if (item.branch == branchArr[0]) {
								let copy = JSON.stringify(item)
								p_data.push(JSON.parse(copy))
								// p_data[0].disabled = true;
								if (branchArr[1]) {
									p_data[0].children = [];
									item.children.forEach(t => {
										if (t.branch == branchArr[1]) {
											let copy = JSON.stringify(t)
											p_data[0].children.push(JSON.parse(copy))
											p_data[0].children[0].disabled = true;
											if (branchArr[2]) {
												p_data[0].children[0].children = [];
												t.children.forEach(tt => {
													if (tt.branch == branchArr[2]) {
														let copy = JSON.stringify(tt)
														p_data[0].children[0].children
															.push(JSON.parse(copy))
														p_data[0].children[0].children[0]
															.disabled = true;
														if (branchArr[3]) {
															p_data[0].children[0].children[
																0].children = [];
															tt.children.forEach(ttt => {
																if (ttt.branch ==
																	branchArr[3]) {
																	let copy = JSON
																		.stringify(
																			ttt)
																	p_data[0]
																		.children[
																			0]
																		.children[
																			0]
																		.children
																		.push(JSON
																			.parse(
																				copy
																			))
																	p_data[0]
																		.children[
																			0]
																		.children[
																			0]
																		.children[
																			0]
																		.disabled =
																		true;
																}
															})
														}
													}
												})
											}
										}
									})
								}
							}
						}
					})
					//是否是超级管理员
					if (branch_level == 1) {
						this.p_data = branch
					} else {
						this.p_data = p_data
					}
				})
			},
			choiceA() {
				this.searchDepartment2 = null
				this.searchDepartment3 = null
				this.searchDepartment4 = null
				this.department2List = []
				this.department3List = []
				this.department4List = []
				this.department1List.forEach(item => {
					if (item.branch == this.searchDepartment1) {
						this.department2List = item.children
					}
				})
			},
			choiceB() {
				this.searchDepartment3 = null
				this.searchDepartment4 = null
				this.department3List = []
				this.department4List = []
				this.department2List.forEach(item => {
					if (item.branch == this.searchDepartment2) {
						this.department3List = item.children
					}
				})
			},
			choiceC() {
				this.searchDepartment4 = null
				this.department4List = []
				this.department3List.forEach(item => {
					if (item.branch == this.searchDepartment3) {
						this.department4List = item.children
					}
				})
			},
			search() {
				this.pagenum = 1;
				this.personnelList()
			},
			reset() {
				this.searchName = '';
				this.searchJob = '';
				this.pagenum = 1;
				if (this.branchArr.length == 0) {
					this.searchDepartment1 = null
					this.searchDepartment2 = null
					this.searchDepartment3 = null
					this.searchDepartment4 = null
					this.department2List = []
					this.department3List = []
					this.department4List = []
				}
				if (this.branchArr.length == 1) {
					this.searchDepartment2 = null
					this.searchDepartment3 = null
					this.searchDepartment4 = null
					//this.department2List=[]
					this.department3List = []
					this.department4List = []
				}
				if (this.branchArr.length == 2) {
					this.searchDepartment3 = null
					this.searchDepartment4 = null
					// this.department3List=[]
					this.department4List = []
				}
				if (this.branchArr.length == 3) {
					this.searchDepartment4 = null
					//this.department4List=[]
				}
				this.personnelList()
			},
			add() {
				this.addVisible = true
			},
			parentDepartmentChanged() {
				if (this.selectedDepartment.length == 1) {
					this.addBranch2 = null
					this.addBranch3 = null
					this.addBranch4 = null
				}
				if (this.selectedDepartment.length == 2) {
					this.addBranch3 = null
					this.addBranch4 = null
				}
				if (this.selectedDepartment.length == 3) {
					this.addBranch4 = null
				}
				if (this.selectedDepartment[0]) {
					this.addBranch1 = this.selectedDepartment[0]
				}
				if (this.selectedDepartment[1]) {
					this.addBranch2 = this.selectedDepartment[1]
				}
				if (this.selectedDepartment[2]) {
					this.addBranch3 = this.selectedDepartment[2]
				}
				if (this.selectedDepartment[3]) {
					this.addBranch4 = this.selectedDepartment[3]
				}
			},
			closeAdd() {
				this.addVisible = false;
				this.addName = '';
				this.addPhone = '';
				this.addJob = '';
				this.selectedDepartment = this.branchArr;
			},
			personnelList() {
				personnel_list(this.company, this.searchName, this.searchJob, this.searchDepartment1, this
						.searchDepartment2, this.searchDepartment3, this.searchDepartment4, this.pagenum, this.pagesize)
					.then(res => {
						if (res.status != 200) {
							return this.$message.error('获取人员列表失败')
						}
						this.personnelData = res.data.personnelData;
						this.total = res.data.total;
					})
			},
			handleSizeChange(newSize) {
				this.pagesize = newSize;
				this.personnelList();
			},
			handleCurrentChange(newPage) {
				this.pagenum = newPage;
				this.personnelList();
			},
			addPersonnel() {
				if (this.addName == '') {
					return this.$message.error('请输入姓名')
				}
				if (this.addJob == '') {
					return this.$message.error('请输入职务')
				}
				if (this.addPhone == '') {
					return this.$message.error('请输入电话')
				}
				const regex = /^1[3456789]\d{9}$/;
				if (!regex.test(this.addPhone)) {
					return this.$message.error('请输入正确的电话号');
				}
				if (!this.addBranch1) {
					return this.$message.error('请选择部门')
				}
				add_personnel(this.company, this.addName, this.addJob, this.addPhone,this.userGender,this.userBirthday,this.addBranch1, this.addBranch2,
					this.addBranch3, this.addBranch4).then(res => {
					if (res.status == 400) {
						return this.$message.error('此电话号码已添加，不可重复添加')
					}
					if (res.status != 200) {
						return this.$message.error('添加人员失败')
					}
					bian_log(`添加人员${this.addName}`)
					this.addVisible = false;
					this.addName = '';
					this.addPhone = '';
					this.addJob = '';
					this.selectedDepartment = this.branchArr;
					this.personnelList()
				})
			},
			edit(row) {
				this.editVisible = true;
				this.editName = row.nickname;
				this.editPhone = row.phone;
				this.editJob = row.job;
				this.edituserGender= row.gender,
				this.editBirthday= row.birthday,
				this.editId = row.id;
				this.selectedDepartmentEdit = [];
				if (row.branch_1) {
					this.editBranch1 = row.branch_1
					this.oldBranch1 = row.branch_1
					this.selectedDepartmentEdit.push(row.branch_1)
				}
				if (row.branch_2) {
					this.editBranch2 = row.branch_2
					this.oldBranch2 = row.branch_2
					this.selectedDepartmentEdit.push(row.branch_2)
				}
				if (row.branch_3) {
					this.editBranch3 = row.branch_3
					this.oldBranch3 = row.branch_3
					this.selectedDepartmentEdit.push(row.branch_3)
				}
				if (row.branch_4) {
					this.editBranch4 = row.branch_4
					this.oldBranch4 = row.branch_4
					this.selectedDepartmentEdit.push(row.branch_4)
				}
			},
			closeEdit() {
				this.editVisible = false;
				this.editName = '';
				this.editPhone = '';
				this.editJob = '';
				this.edituserBirthday = '',
				this.edituserGender = '',
				this.selectedDepartmentEdit = [];
				this.editId = '';
			},
			parentDepartmentChangedEdit() {
				if (this.selectedDepartmentEdit.length == 1) {
					this.editBranch2 = null
					this.editBranch3 = null
					this.editBranch4 = null
				}
				if (this.selectedDepartmentEdit.length == 2) {
					this.editBranch3 = null
					this.editBranch4 = null
				}
				if (this.selectedDepartmentEdit.length == 3) {
					this.editBranch4 = null
				}
				if (this.selectedDepartmentEdit[0]) {
					this.editBranch1 = this.selectedDepartmentEdit[0]
				}
				if (this.selectedDepartmentEdit[1]) {
					this.editBranch2 = this.selectedDepartmentEdit[1]
				}
				if (this.selectedDepartmentEdit[2]) {
					this.editBranch3 = this.selectedDepartmentEdit[2]
				}
				if (this.selectedDepartmentEdit[3]) {
					this.editBranch4 = this.selectedDepartmentEdit[3]
				}
			},
			editPersonnel() {
				if (this.editName == '') {
					return this.$message.error('请输入姓名')
				}
				if (this.editJob == '') {
					return this.$message.error('请输入职务')
				}
				if (this.editPhone == '') {
					return this.$message.error('请输入电话')
				}
				const regex = /^1[3456789]\d{9}$/;
				if (!regex.test(this.editPhone)) {
					return this.$message.error('请输入正确的电话号');
				}
				if (!this.editBranch1) {
					return this.$message.error('请选择部门')
				}
				edit_personnel(this.company, this.editName, this.editJob, this.editPhone,this.edituserGender,this.edituserBirthday, this.editBranch1, this
					.editBranch2, this.editBranch3, this.editBranch4, this.editId, this.oldBranch1, this.oldBranch2,
					this.oldBranch3, this.oldBranch4).then(res => {
					if (res.status != 200) {
						return this.$message.error('编辑人员失败')
					}
					bian_log(`编辑人员${this.editName}`)
					this.editVisible = false;
					this.editName = '';
					this.editPhone = '';
					this.editJob = '';
					this.selectedDepartmentEdit = [];
					this.editId = '';
					this.personnelList()
				})
			},
			remove(row) {
				if (row.branch_1) {
					this.removeBranch1 = row.branch_1
				}
				if (row.branch_2) {
					this.removeBranch2 = row.branch_2
				}
				if (row.branch_3) {
					this.removeBranch3 = row.branch_3
				}
				if (row.branch_4) {
					this.removeBranch4 = row.branch_4
				}
				this.$confirm('此操作将永久删除该人员', '是否确定删除人员', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					remove_personnel(this.company, row.id, this.removeBranch1, this.removeBranch2, this
						.removeBranch3, this.removeBranch4).then(res => {
						if (res.status != 200) {
							return this.$message.error('删除人员失败')
						}
						bian_log(`删除人员${row.name}`)
						this.personnelList()
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			batch() {
				this.uploadVisible = true
			},
			parentDepartmentChangedUpload() {
				if (this.selectedDepartmentUpload.length == 1) {
					this.uploadBranch2 = null
					this.uploadBranch3 = null
					this.uploadBranch4 = null
				}
				if (this.selectedDepartmentUpload.length == 2) {
					this.uploadBranch3 = null
					this.uploadBranch4 = null
				}
				if (this.selectedDepartmentUpload.length == 3) {
					this.uploadBranch4 = null
				}
				if (this.selectedDepartmentUpload[0]) {
					this.uploadBranch1 = this.selectedDepartmentUpload[0]
				}
				if (this.selectedDepartmentUpload[1]) {
					this.uploadBranch2 = this.selectedDepartmentUpload[1]
				}
				if (this.selectedDepartmentUpload[2]) {
					this.uploadBranch3 = this.selectedDepartmentUpload[2]
				}
				if (this.selectedDepartmentUpload[3]) {
					this.uploadBranch4 = this.selectedDepartmentUpload[3]
				}
			},
			excelUpload(params) {
				this.uploadFile = params.file
			},
			closeUpload() {
				this.uploadVisible = false;
			},
			batchUpload() {
				if (this.selectedDepartmentUpload[0]) {
					this.uploadBranch1 = this.selectedDepartmentUpload[0]
				}
				if (this.selectedDepartmentUpload[1]) {
					this.uploadBranch2 = this.selectedDepartmentUpload[1]
				}
				if (this.selectedDepartmentUpload[2]) {
					this.uploadBranch3 = this.selectedDepartmentUpload[2]
				}
				if (this.selectedDepartmentUpload[3]) {
					this.uploadBranch4 = this.selectedDepartmentUpload[3]
				}
				if (this.uploadFile == '') {
					return this.$message.error('请上传excel')
				}
				if (!this.uploadBranch1) {
					return this.$message.error('请选择部门')
				}
				let formData = new FormData()
				formData.append('excel', this.uploadFile)
				formData.append('company', this.company)
				formData.append('branch1', this.uploadBranch1)
				formData.append('branch2', this.uploadBranch2)
				formData.append('branch3', this.uploadBranch3)
				formData.append('branch4', this.uploadBranch4)
				excel_upload(formData).then(res => {
					if (res.status == 400) {
						return this.$message.error('请填写excel内容')
					}
					if (res.status != 200) {
						return this.$message.error('导入失败')
					}
					bian_log(`批量导入人员`)
					this.$message.success('导入成功')
					this.uploadVisible = false;
					this.personnelList()
				})
			},
			clearCheckedNodes() {
			}
		},
		created() {
			let company = sessionStorage.getItem('company')
			this.company = company;
			this.branch_content = sessionStorage.getItem('branch-content');
			this.branch_level = sessionStorage.getItem('level');
			this.departmentList();
			//this.personnelList()          
		},
	};
</script>
<style lang='less' scoped>
</style>
